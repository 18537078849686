<template>
  <div
    class="w-full h-full pt-2"
  >
  <div class="w-full flex flex-col"
  >
  <InProgress />
  </div>
  </div>
</template>

<script>
import InProgress from './InProgress';

export default {
    name: 'ActiveRequests',
    components: {
        InProgress,
    },
  props: {
    option: {
      type: String,
      default: 'In-Progress',
    },
  },
}
</script>
