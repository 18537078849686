<template>
  <div class="flex  mr-6 mb-1">
    <span
      class="flex-none py-2 px-2 rounded-lg"
      :style="{background: iconCardBg }"
    >
      <Icon
        class-name="text-darkPurple mx-auto self-center"
        :style="{color: color }"
        size="s"
        :icon-name="iconName"
      />
    </span>
    <span class="block  px-5 pr-8  flex-none border-dashed border-r border-mild-gray">
      <span
        class="block text-xs uppercase text-romanSilver font-semibold"
      >
        {{ label }}
      </span>
      <span
        class="block text-lg font-bold leading-tight"
        :style="{color: color }"
      >
        {{ figure }}
      </span>
    </span>
  </div>
</template>

<script>
import Icon from './Icon';
// import Card from './Card';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    figure: {
      type: String,
      required: true,
    },
    iconCardBg: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
  },
  components: {
    Icon,
    // Card,
  },

  // data() {
  //   return {
  //     IconCardbgColor: 'rgba(50, 28, 59, 0.08)',
  //   };
  // },
};
</script>
