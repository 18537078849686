var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full pt-5"},[_c('div',{staticClass:"w-full flex flex-col"},[(_vm.requestData.length > 0 || _vm.loading)?_c('Table',{staticClass:"w-full mt-4 mb-4",attrs:{"headers":_vm.requestHeader,"items":_vm.requestData,"loading":_vm.loading,"pagination-list":_vm.metaData,"page-sync":""},on:{"page":function($event){return _vm.handlePage($event)},"itemsPerPage":function($event){return _vm.handleItemsPerPage($event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(item.initiatorId)?_c('div',{staticClass:"pt-3 pb-3",staticStyle:{"width":"230px"}},[_c('div',{staticClass:"flex"},[(item.data.photo != null && item.data.photo != '')?_c('img',{staticClass:"mr-2",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":item.data.photo}}):_c('div',{staticClass:"text-blueCrayola border text-center font-semibold pt-2 mr-2",staticStyle:{"height":"35px","width":"35px","border-radius":"5px"}},[_vm._v(" "+_vm._s(_vm.$getInitials(`${item.data.fname} ${item.data.lname}`))+" ")]),_c('div',{staticClass:"w-auto flex flex-col"},[_c('p',{staticClass:"text-sm font-semibold text-darkPurple"},[_vm._v(" "+_vm._s(`${item.data.fname} ${item.data.lname}`)+" ")])])])]):(item.dateSubmitted)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$DATEFORMAT(new Date(item.data.dateSubmitted), "MMMM dd, yyyy"))+" ")])]):(item.requestName)?_c('div',[_c('span',{staticClass:"cursor-pointer underline",on:{"click":function($event){return _vm.priviledgedActions(
                {
                  requestId: item.data.requestId,
                  requestSlug: item.data.requestSlug,
                },
                'viewDetailedApproval'
              )}}},[_vm._v(" "+_vm._s(item.data.requestName)+" ")])]):(item.approvalProgress)?_c('div',[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"flex"},[_c('p',{staticClass:"font-bold text-darkPurple text-xl"},[_vm._v(" "+_vm._s(Math.ceil(item.data.approvalProgress))+"% ")]),_c('p',{staticClass:"text-romanSilver ml-1 mt-1 text-sm"},[_vm._v("Completed")])]),_c('span',{staticClass:"flex"},[_c('ProgressBar',{attrs:{"value":Math.ceil(item.data.approvalProgress)}})],1)])]):(item.status)?_c('div',{staticClass:"ml-2"},[_c('Badge',{staticStyle:{"padding":"10px","min-height":"32px","font-size":"14px","font-weight":"600"},attrs:{"label":item.data.status === 'Approved' ? 'Approved' : 'Disapproved',"variant":"primary","background-color":item.data.status === 'Approved'
                ? 'rgba(19, 181, 106, 0.08)'
                : 'rgba(241, 90, 41, 0.15)',"color":item.data.status === 'Approved' ? '#13B56A' : '#E99323'}})],1):(item.requestId)?_c('div',{staticClass:"ml-2 text-blueCrayola cursor-pointer",on:{"click":function($event){return _vm.priviledgedActions(item.data.requestId, 'viewApprovalProgress')}}},[_c('Icon',{staticClass:"mt-1",attrs:{"icon-name":"icon-eye","size":"xs"}})],1):_vm._e()]}}],null,false,3425703647)}):_c('div',{staticClass:"w-full flex flex-col justify-center items-center mt-10"},[_c('icon',{staticStyle:{"width":"300px"},attrs:{"icon-name":"empty_agreement","size":"l"}}),_c('div',{staticClass:"w-1/2 text-base text-center"},[_vm._v(" No available Data ")])],1)],1),_c('ApprovalProgress',{ref:"approval_status"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }