<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <div class="w-full" v-if="hasModuleAccess">
        <div class="w-full h-full">
          <ViewApprovals
          :total="totalRequest"
          :approved="approvedRequest"
          :disapproved="disapprovedRequest"
          :open="openRequest"
          v-if="totalRequest > 0"
          />
          <EmptyApprovals
          :total="totalRequest"
          :approved="approvedRequest"
          :disapproved="disapprovedRequest"
          :open="openRequest"
          v-else
          />
        </div>
      </div>
      <div v-else>
        <ErrorComponent />
      </div>
    </template>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import ErrorComponent from "@/modules/Admin/error403";
import EmptyApprovals from './EmptyApprovals';
import ViewApprovals from './viewapprovals/ViewApprovals';

export default {
  name: 'Approvals',
  components: {
    EmptyApprovals,
    ViewApprovals,
    Loader,
    ErrorComponent
  },
  data() {
    return {
      isLoading: true,
      hasModuleAccess: false,
      showError: false,
      hasApprovals: true,
      loading: true,
      openModal: false,
      totalRequest: '',
      approvedRequest: '',
      disapprovedRequest: '',
      openRequest: '',
    };
  },
  methods: {
    async getApprovalNumber() {
      const userId = this.$AuthUser.id;
      const params = "myapprovals";
      await this.$_getApprovalNumbers(params, userId).then(result => {
          this.loading = false;
          this.totalRequest = result.data.TotalRequest;
          this.approvedRequest = result.data.ApprovedRequest;
          this.disapprovedRequest = result.data.DisapprovedRequests;
          this.openRequest = result.data.OpenRequest;
        }).catch(() => {
          this.loading = false;
        });
    },
  },
  async created() {
    this.isLoading = true

    try {
      await this.$handlePrivilege("myApprovals", 'viewRegister')
      this.hasModuleAccess = true
      await this.getApprovalNumber()
    } catch (error) {
      this.hasModuleAccess = false
    }

    this.isLoading = false
  }
};
</script>

